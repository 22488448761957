import React, { useContext, useState } from "react";
import moment from "moment";
import { Form, Button, Card } from "react-bootstrap";
import pluralize from "pluralize";
import Api from "../../utils/apiCall";
import UserAvatar from "../users/UserAvatar";
import IconButton from "../IconButton";
import Access from "../../utils/access";
import EditContentField from "../EditContentField";
import LoginModal from "../auth/LoginModal";
import User from "../users/User";
import Icons from "../icons";
import { PostContext } from "./PostPage";

const PostComment = ({ comment, onDelete, state }) => {
  const [mode, setMode] = useState();
  const [commentData, setCommentData] = useState(comment);
  const postDataContext = useContext(PostContext);

  const trash = async () => {
    await Api.call("post/comment", "DELETE", { id: comment.id });
    onDelete(comment.id);
    postDataContext.setPostData({
      ...postDataContext.postData,
      comments: parseInt(postDataContext.postData.comments) - 1,
    });
  };
  return (
    <Card className="comment-card">
      <Card.Body>
        {/* <b><User user={commentData.User} {moment(commentData.createdAt).fromNow()}/> &nbsp;</b> */}
        <div className="d-flex justify-content-between">
          {commentData?.User && (
            <b>
              <User user={commentData.User} />
            </b>
          )}
          <span className="date-cm">
            {commentData?.createdAt &&
              moment(commentData.createdAt).format("DD-MM-YY")}
            &nbsp;
          </span>
        </div>

        <div className="editor-content">
          {mode === "edit" ? (
            <CommentForm
              comment={commentData}
              onComment={(c) => {
                setMode(false);
                setCommentData(c);
              }}
              state={state}
              cancel={() => setMode(false)}
            />
          ) : (
            <>
              <div
                className="comment-content"
                dangerouslySetInnerHTML={{ __html: commentData.comment }}
              />
              {Access.can("manage", state.user) ||
              state.user.id === commentData.User.id ? (
                <div className="mt-2">
                  <IconButton
                    variant="outline-secondary"
                    icon="trash"
                    onClick={trash}
                    className="mr-2 post-delete-button"
                  >
                    Delete
                  </IconButton>
                  <IconButton onClick={() => setMode("edit")}>Edit</IconButton>
                </div>
              ) : null}
            </>
          )}
        </div>
      </Card.Body>
      <hr />
    </Card>
  );
};

const Panel = ({ comments, post, data, state }) => {
  const [commentsData, setCommentsData] = useState(comments);
  const onComment = (comment) => {
    setCommented(true);
    setCommentsData([...commentsData, comment]);
  };
  const [commented, setCommented] = useState(false);
  return (
    <div className="comment-nos">
      <a className="comments" name="comments">
        <Icons.Chat /> {commentsData.length}{" "}
        {pluralize("comment", commentsData.length)}{" "}
      </a>
      <hr className="top-hr-cm" />
      <List
        state={state}
        comments={commentsData}
        onDelete={(id) =>
          setCommentsData(commentsData.filter((c) => c.id !== id))
        }
      />

      {/* { !state.user?.id ? <IconButton icon="chat" onClick={()=> LoginModal.show()}>Sign In to Comment</IconButton> : ( */}
      {!state.user?.id ? (
        <Button
          className="btn btn-outline-secondary mt-3 mb-5"
          style={{ backgroundColor: "transparent", color: "#000" }}
          onClick={() => LoginModal.show()}
        >
          Sign In to Comment
        </Button>
      ) : (
        <>
          {!commented ? (
            <Card>
              <Card.Body>
                <CommentForm post={post} state={state} onComment={onComment} />
              </Card.Body>
            </Card>
          ) : (
            <Button onClick={() => setCommented(false)} variant="outline-info">
              Post a new comment
            </Button>
          )}
        </>
      )}
    </div>
  );
};

const List = ({ comments, onDelete, state }) =>
  comments.map((c) => (
    <PostComment key={c.id} onDelete={onDelete} comment={c} state={state} />
  ));

const CommentForm = ({
  post,
  state,
  onComment,
  comment = { comment: "" },
  cancel,
}) => {
  const [commentData, setCommentData] = useState(comment);
  const [clearComment, setClearComment] = useState(Math.random());
  const postDataContext = useContext(PostContext);

  const submitComment = async () => {
    //validate if comment contains also characters instead of only white spaces
    const validComment = validateComment(commentData.comment);
    if (!validComment) {
      setClearComment(Math.random());
      return;
    }

    try {
      const sub = await Api.call("post/comment", "POST", {
        ...commentData,
        PostId: commentData.PostId || post.id,
      });

      sub.User = state.user;

      onComment({
        ...commentData,
        id: commentData.id || sub.id,
        User: state.user,
        PostId: commentData.PostId || post.id,
        createdAt: new Date().toLocaleDateString(),
      });

      //if this is a new comment then clear previous comment and increase comment count in top nav bar
      if (!comment.id) {
        setCommentData({ comment: "" });
        postDataContext.setPostData({
          ...postDataContext.postData,
          comments: parseInt(postDataContext.postData.comments) + 1,
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div className="comment-content">
      <h5>{commentData.id ? "Edit Comment" : "Post New Comment"}</h5>
      <EditContentField
        content={commentData.comment}
        update={(c) => setCommentData({ ...commentData, comment: c })}
        key={clearComment}
      />
      <div style={{ textAlign: "right", margin: "10px 0 0" }}>
        {commentData.id ? (
          <IconButton
            icon="cancel"
            block
            className="mr-2"
            variant="outline-info"
            onClick={() => cancel()}
          >
            Cancel
          </IconButton>
        ) : null}
        <IconButton
          icon="comment"
          block
          variant="outline-info"
          onClick={() => submitComment()}
        >
          {commentData.id ? "Save" : "Submit"}
        </IconButton>
      </div>
    </div>
  );
};

const validateComment = (comment) => {
  //if null comment return as invalid comment
  if (!comment || comment?.length === 0) return false;

  //removing <p></p> tags from html text and trim
  const extractedComment = comment.replace("<p>", "").replace("</p>", "");
  return extractedComment.trim().length > 0 ? true : false;
};

PostComment.Form = CommentForm;
PostComment.List = List;
PostComment.Panel = Panel;

export default PostComment;
