

import React from 'react';
import slugify from 'slugify';
import {
  FormControl, InputGroup,
} from 'react-bootstrap';


const SlugField = ({ slug, update, path = '' }) => {
  return (
    <form-group>
      <InputGroup className="slug-news" >
        <InputGroup.Text>https://strideequity.com.au/{path}</InputGroup.Text>
        <FormControl
          type="text"
          value={slug}
          onChange={(e) => update(slugify(e.target.value))}
        />
      </InputGroup>
    </form-group>
  )
}

export default SlugField;
