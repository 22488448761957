import React, { useRef, useState, useEffect } from "react";
import { Badge, Form } from "react-bootstrap";
import ContentEditable from "react-contenteditable";
import sanitizeHtml from "sanitize-html";

const Editable = ({
  key1,
  edit,
  update,
  value,
  type = "content",
  render = false,
  options,
  decimal = false,
  percentage = false,
  max,
  placeholder = "",
  disabled = false,
  onKeyDown = () => {},
}) => {
  const [val, setVal] = useState(value);
  const [isPercentage, setIsPercentage] = useState(percentage);

  useEffect(() => {
    setVal(value);
  }, [value]);

  useEffect(() => {
    setIsPercentage(percentage);
  }, [percentage]);

  let temp = val;
  const onChangeDecimal = (num) => {
    if (max && num <= max) {
      let x = parseFloat(num);
      const validated = isPercentage
        ? x >= 0 && x <= 100 && num.match(/^(\d*\.{0,1}\d{0,2}$)/)
        : num.match(
            decimal == 2
              ? /^(\d*\.{0,1}\d{0,2}$)/
              : decimal == 3 && /^(\d*\.{0,1}\d{0,3}$)/
          );

      if (validated || isNaN(x)) {
        temp = num;
        setVal(num);
      }
    }
  };

  const onChangeNonDecimal = (num) => {
    if (max && num <= max) {
      temp = num;
      setVal(num);
    }
  };

  const handleKeypress = (e) => {
    const characterCode = e.key;
    if (characterCode === "ArrowUp" || characterCode === "ArrowDown")
      e.preventDefault();
    else if (!decimal) {
      if (characterCode === "." || characterCode === "-") e.preventDefault();
    } else {
      return e;
    }
  };

  if (type === "number") {
    return (
      <>
        {edit ? (
          <Form.Control
            min={options.min}
            max={options.max}
            step={options.step}
            size="sm"
            type="number"
            value={val}
            onKeyDown={(e) => {
              handleKeypress(e);
            }}
            onChange={(e) => {
              decimal
                ? onChangeDecimal(e.target.value)
                : onChangeNonDecimal(e.target.value);
              update(temp);
            }}
            disabled={disabled}
          />
        ) : (
          <>{render ? render(value) : val}</>
        )}
      </>
    );
  }

  if (!edit)
    return (
      sanitizeHtml(value, { allowedTags: [], allowedAttributes: {} }) || " "
    );

  return (
    <div className="edit-rows">
      <ContentEditable
        placeholder={placeholder}
        html={val}
        disabled={!edit || disabled}
        className={`edit-main ${disabled ? "disabled-edit" : ""}`}
        onChange={(e) => {
          let input = e.currentTarget.textContent;
          if (options?.maxChars && input.length > options.maxChars) {
            input = input.substring(0, options.maxChars);
            update(input);
            setVal(input);
            return;
          }
          update(input);
          setVal(e.target.value);
        }}
        onKeyDown={(e) => onKeyDown(e)}
      />
      {options?.maxChars ? (
        <div className="text-right" style={{ fontSize: "12px" }}>
          <Badge variant="secondary">max {options.maxChars} chars</Badge>
        </div>
      ) : null}
    </div>
  );
};

export default Editable;
