import moment from "moment";
import { useS3Upload } from "next-s3-upload";
import { useRouter } from "next/router";
import pluralize from "pluralize";
import React, { createContext, useEffect, useState } from "react";
import {
  Button,
  Col,
  Container,
  Form,
  FormGroup,
  Navbar,
  Row,
} from "react-bootstrap";
import { NewsPub } from "../../pages/news";
import Access from "../../utils/access";
import Api from "../../utils/apiCall";
import Colors from "../../utils/colorPaletteExtracter";
import { SmallStatusAction } from "../../utils/constant";
import Global from "../../utils/globalEvents";
import Bg from "../../utils/imageBackgrounds";
import imageResizer, { dataURIToFile } from "../../utils/imageResizer";
import ColorField from "../ColorField";
import EditContentField from "../EditContentField";
import Editable from "../Editable";
import IconButton from "../IconButton";
import SlugField from "../SlugField";
import Status from "../Status";
import UploadAndCrop from "../UploadAndCrop";
import Icons from "../icons";
import Layout from "../layout";
import PostComment from "./PostComment";
import PostLikes from "./PostLike";

const PostPage = ({ state, post, data, postTypes, relatedArticles }) => {
  const [mode, setMode] = useState(post.id ? false : "edit");
  const [postData, setPostData] = useState(post);
  const [nameValidation, setNameValidation] = useState(false);
  const { uploadToS3 } = useS3Upload();
  const router = useRouter();

  useEffect(() => {
    setPostData({
      ...post,
      PostTypeId: post?.PostTypeId ? post.PostTypeId : 1,
    });
  }, [post]);

  let backupData = { ...post };
  const { user } = state;
  const canEdit =
    user.id && (Access.can("manage", user) || post.UserId === user.id);

  const save = async () => {
    if (postData?.name?.length === 0 || !postData?.name) {
      //setting name validation message to show
      setNameValidation(true);

      //setting name validation message to hide after 3 seconds
      setTimeout(() => setNameValidation(false), 3000);
      return;
    }

    const result = await Api.call("post", "POST", { ...postData });
    setMode(false);

    Global.dispatch("editmodeHide", false);

    // ** bellow code snippet will runs if this post is a new post after click on save.
    // ** it will replace the url in the browser with post id.
    // ** but this will not required for the functionality.

    /*
        if (!backupData.id)
          router.push(`/post/${result.id}`, null, { shallow: false });
    */

    backupData = { ...postData };

    //if this post is an edited one it will replace the url as url also can be edited
    if (postData?.slug) {
      return router.replace(`/post/${postData.slug}`);
    }
  };

  const cancel = () => {
    if (post?.new) {
      router.push(`/dashboard/posts`, null, { shallow: false });
    } else {
      setPostData(backupData);
      Global.dispatch("editmodeHide", false);
      setMode(false);
    }
  };
  const edit = () => {
    Global.dispatch("editmodeHide", true);
    setMode("edit");
  };
  const saveImage = async (imgData, imgElement) => {
    Global.setLoading(true);
    const file = dataURIToFile(imgData);
    const imageFile = await imageResizer(file, {
      width: 1600,
      height: 900,
      filename: "post.jpg",
    });
    const image = (await uploadToS3(imageFile)).url;
    const thumbFile = await imageResizer(imageFile, {
      width: 640,
      height: 360,
      filename: "post-thumb.jpg",
    });
    const thumb = (await uploadToS3(thumbFile)).url;
    const colors = Colors.extract(imgElement);
    const update = {
      coverImage: { image, thumb },
      colors,
    };
    updateFields(update);
    Global.setLoading(false);
  };
  const updateFields = (data) => {
    setPostData({ ...postData, ...data });
  };
  const theme = () => (postData.colors?.theme === "dark" ? "dark" : "light");

  const filteredPostTypeById =
    postTypes?.filter(
      (postType) => postType?.id === parseInt(postData?.PostTypeId)
    ) || [];

  const postType =
    filteredPostTypeById.length > 0 ? filteredPostTypeById[0]?.name : null;

  return (
    <Layout title={`Stride Equity | ${post.name}`} state={state}>
      {canEdit ? (
        <Navbar
          className="save-bar bottom-nav-bar"
          fixed="bottom"
          variant="dark"
        >
          <Container className="text-right edit-offer">
            {mode === "edit" ? (
              <>
                <IconButton variant="light" icon="cancel" onClick={cancel}>
                  Cancel
                </IconButton>
                &nbsp;
                <div>
                  <Status.Select
                    statusList={Object.values(SmallStatusAction)}
                    selected={postData.status}
                    onChange={(status) => updateFields({ status })}
                  />
                  &nbsp;
                  <IconButton
                    variant="outline-info"
                    icon="check"
                    onClick={save}
                  >
                    Save
                  </IconButton>
                </div>
              </>
            ) : (
              <>
                <Navbar.Text>
                  {postData.createdAt == postData.updatedAt ? (
                    <>created {moment(postData.createdAt).fromNow()}&nbsp;</>
                  ) : (
                    <>updated {moment(postData.updatedAt).fromNow()}&nbsp;</>
                  )}
                  <Status status={postData.status} />
                </Navbar.Text>
                <div className="justify-content-end">
                  <IconButton variant="outline-info" onClick={edit}>
                    Edit
                  </IconButton>
                </div>
              </>
            )}
          </Container>
        </Navbar>
      ) : null}
      {mode === "edit" ? (
        <Container>
          {!edit && postData.colors
            ? postData.colors.palette.map((color, index) => (
                <ColorField
                  key={index}
                  value={color}
                  placement="bottom"
                  onChange={(c) => {
                    const palette = [...postData.colors.palette];
                    palette.splice(index, 1, c);
                    updateFields({ colors: { ...postData.colors, palette } });
                  }}
                />
              ))
            : null}
          {!edit && (
            <Form.Check
              style={{ display: "inline" }}
              checked={postData.colors?.theme === "dark"}
              label="dark theme"
              id="theme"
              onChange={(e) => {
                const theme = e.target.checked ? "dark" : "light";
                updateFields({ colors: { ...postData.colors, theme } });
              }}
            />
          )}
        </Container>
      ) : null}

      <Container className="news-post">
        <Row>
          <Col>
            <div className="page-subtitle-area">
              <h3 className="title-3 text-right">
                <span className="m-item">
                  <div className="d-flex justify-content-between">
                    <span>
                      {/* Posted */}
                      {postData.updatedAt
                        ? moment(postData.updatedAt).format("MMM Do YY")
                        : null}
                      <span className="author">
                        &nbsp; by &nbsp;
                        {/* <UserAvatar user={postData.User} /> */}
                        {postData.User?.name || "author"}
                      </span>
                    </span>
                    <div className="header-comment-ls">
                      {postData.id ? (
                        <>
                          <PostLikes
                            post={post}
                            like={data.like}
                            user={state.user}
                          />
                          <Button variant="link" href="#comments">
                            <Icons.Chat /> {postData.comments}
                            &nbsp;
                            {pluralize("comment", postData.comments)}
                          </Button>
                        </>
                      ) : null}
                    </div>
                  </div>
                </span>
              </h3>
            </div>
          </Col>
        </Row>
        <Row>
          <Col md={6} className="side-border">
            <div className="header-comment-sc">
              {postData.id ? (
                <>
                  <PostLikes post={post} like={data.like} user={state.user} />
                  <Button variant="link" href="#comments">
                    <Icons.Chat /> {post.comments}
                    {pluralize("comment","", postData.comments)}
                  </Button>
                </>
              ) : null}
            </div>
            <div style={{ alignSelf: "flex-end", width: "100%" }}>
              <div>
                <h1 className="side-title">
                  {mode === "edit" ? (
                    <Editable
                      options={{ maxChars: 100 }}
                      edit={mode === "edit"}
                      value={postData.name}
                      update={(name) => updateFields({ name })}
                    />
                  ) : (
                    <div dangerouslySetInnerHTML={{ __html: postData.name }} />
                  )}
                </h1>

                {/* showing name validations if user click on save button without providing a name for the post */}
                {nameValidation && (
                  <div className="mt-3 pb-3">
                    <span style={{ color: "red" }}>
                      Please enter a post name !
                    </span>
                  </div>
                )}

                {/* post type category label and editor dropdown */}
                {mode === "edit" && (
                  <FormGroup>
                    <Form.Label>Post type</Form.Label>
                    <Form.Control
                      className="cat-input"
                      as="select"
                      onChange={(e) =>
                        updateFields({ PostTypeId: e.target.value })
                      }
                      value={postData?.PostTypeId}
                      placeholder="Post Type"
                      defaultValue={1}
                    >
                      {postTypes?.map((postType) => (
                        <option value={postType?.id} key={postType?.id}>
                          {postType?.name}
                        </option>
                      ))}
                    </Form.Control>
                  </FormGroup>
                )}
              </div>
            </div>
          </Col>
          <Col md={6} className="img-m-article">
            <div>
              <div
                style={{
                  // height: "22vw",
                  maxHeight: "550px",
                  display: "flex",
                  background: Bg.generate(postData),
                }}
                className={`image-header-${theme()}`}
              ></div>

              {mode === "edit" && (
                <>
                  <div className=" mt-3 pb-3">
                    <SlugField
                      path="post/"
                      slug={postData.slug}
                      update={(slug) => updateFields({ slug })}
                    />
                  </div>
                  <span className="btn-img">
                    <UploadAndCrop
                      variant="light"
                      aspectRatio={16 / 9}
                      update={saveImage}
                      label="upload cover"
                    />
                    {postData.coverImage ? (
                      <IconButton
                        variant="dark add-left-m"
                        onClick={() =>
                          updateFields({ coverImage: false, colors: false })
                        }
                        icon="cancel"
                      >
                        Remove picture
                      </IconButton>
                    ) : null}
                  </span>
                </>
              )}
            </div>

            <div
              style={{ color: "#4A91C5" }}
              className="airy short-title-effective lead"
            >
              {mode === "edit" ? (
                <Editable
                  options={{ maxChars: 200 }}
                  edit={mode === "edit"}
                  value={postData.description}
                  update={(description) => updateFields({ description })}
                />
              ) : (
                <div
                  className="short-title-effective"
                  dangerouslySetInnerHTML={{ __html: postData.description }}
                />
              )}
            </div>

            <div>
              {mode === "edit" ? (
                <div className="post-content">
                  <EditContentField
                    content={postData.content}
                    update={(content) => updateFields({ content })}
                  />
                </div>
              ) : null}
              {mode !== "edit" ? (
                <div
                  className="post-content"
                  dangerouslySetInnerHTML={{ __html: postData.content }}
                />
              ) : null}
            </div>
          </Col>
        </Row>
        <hr className="newspost-hr" />
        <Row>
          <Col md={6}>
            <div style={{ alignSelf: "flex-end", width: "100%" }}>
              <div>
                <h2 className="title-comment-section">Comments</h2>
              </div>
            </div>
          </Col>
          <Col md={6}>
            <div className="comment-section">
              {postData.id ? (
                <PostContext.Provider value={{ postData, setPostData }}>
                  <PostComment.Panel
                    comments={data.comments}
                    post={postData}
                    state={state}
                  />
                </PostContext.Provider>
              ) : null}
            </div>
          </Col>
        </Row>
        <hr className="mt-5" />
        {mode !== "edit" && (
          <Row>
            <Col>
              <div style={{ alignSelf: "flex-end", width: "100%" }}>
                <div>
                  <h1 className="title-comment-section">Related Articles</h1>
                </div>

                <Container>
                  <Row className="related-articles">
                    {relatedArticles.map((post) => (
                      <Col className="home-news-content" lg={4}>
                        <div key={post.id} className="row-eq-height">
                          <div className="home-news-wrapper">
                            <NewsPub post={post} />
                          </div>
                        </div>
                      </Col>
                    ))}
                  </Row>
                </Container>
              </div>
            </Col>
          </Row>
        )}
      </Container>
    </Layout>
  );
};

export const PostContext = createContext();

export default PostPage;
